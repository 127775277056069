@keyframes needleGrow {
  0% {
    transform: translate(-50%, 3.5%) rotate(-60deg);
    opacity: 0;
  }

  100% {
    transform: translate(-50%, 3.5%) rotate(0deg);
    opacity: 1;
  }
}

@keyframes wobble {
  0% {
    transform: rotate(-2deg);
  }
  50% {
    transform: rotate(2deg);
  }
  100% {
    transform: rotate(-2deg);
  }
}

#needleWrapper {
  display: block;
  width: 80vw;
  max-width: 1000px;
  position: absolute;
  bottom: 0;
  left: 50%;
  z-index: 100;
  opacity: 0;
  transform-origin: bottom center;
  transform: translate(-50%, 3.5%) rotate(-60deg);
  animation: needleGrow 600ms ease-in-out;
  animation-fill-mode: forwards;
  animation-delay: 2000ms;
}

#needleImage {
  width: 100%;
  transform-origin: bottom center;
  animation: wobble 2s infinite ease-in-out
}

#overlayText {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
