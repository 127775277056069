
svg.segment {
  transform-origin: bottom right;
  position: absolute;
  bottom: 0;
  left: 50%;
  z-index: 10;
}

@keyframes grow {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

svg.segment .segmentBackground {
  transform-origin: bottom right;
  transform: scale(0);
  animation: grow 2s ease-in-out;
  animation-fill-mode: forwards;
}
