
footer {
  display: block;
  padding: 8px;
  background: rgba(0,0,0, 0.2);
  margin-top: auto;
}

footer div {
  max-width: 500px;
  margin: 0 auto;
}

footer p {
  color: #ffffff;
  font-size: 14px;
}

ul.termsLinks {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  list-style: none;
  padding: 5px 0;
  margin: 0;
}

ul.termsLinks li {
  padding: 0 10px;
}

ul.termsLinks li,
ul.termsLinks li a {
  color: white;
  text-decoration: none;
  font-weight: bold;
  text-transform: uppercase;
}

ul.termsLinks li a {
  transition: all 200ms;
  opacity: 0.7;
  position: relative;
  padding: 8px 0;
}

ul.termsLinks li a span.underline {
  transition: all 200ms;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 0;
  border-top: solid 1px #ffffff;
  opacity: 0;
  transform: scaleX(0);
}

ul.termsLinks li a:hover {
  opacity: 1;
}
ul.termsLinks li a:hover span.underline {
  transform: scaleX(1);
  opacity: 1;
}