
#socialLinks {
  background: #141C46;
  border-radius: 8px;
  display: inline-flex;
  flex-wrap: wrap;
  grid-template-columns: 200px 1fr;
  vertical-align: middle;
  align-items: center;
  justify-content: center;
  /* width: 90vw; */
  max-width: 90vw;
  margin: 0 auto;
  margin-bottom: 20px;
  padding: 4px 12px;
  /* outline: solid 1px hotpink; */
}

@media screen and (min-width: 1000px) {
  #socialLinks {
    position: absolute;
    top: 6vh;
    right: 6vh;
  }
}

#socialLinks section {
  /* outline: dashed 1px pink; */
  /* display: inline-block; */

}

#hashtagWrapper {
  position: relative;
  display: grid;
  vertical-align: center;
  align-items: center;
  paddinG: 0 10px;
}

#hashtagWrapper img {
  position: relative;
  width: 150px;
  /* top: 50%; */
  /* margin-top: -50%; */
}


#linksWrapper {
}

#linksWrapper img {
  width: 20px;
  height: auto;
  margin: 5px 10px;
}

#linksWrapper a {
  transition: all 200ms;
}
#linksWrapper a:hover {
  opacity: 0.5;
}