
@keyframes labelGrow {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.label {
  width: 45vw;
  max-width: 400px;
  fill: #ffffff;
  opacity: 0;
  animation: labelGrow 0.8s ease-in-out;
  animation-fill-mode: forwards;
}
