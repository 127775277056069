
.gaugeWrapper {
  position: relative;
  display: block;
  z-index: 0;
}

#segmentWrapper {
  outline: solid 1px pink;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%);
  margin: 0;
  padding: 0;
  z-index: 100000;
}

#segmentWrapper svg {
  margin: 0;
  padding: 0;
  z-index: 100000;
}

#labelWrapper {
  position: absolute;
  bottom: 0;
  right: 50%;
  z-index: 100000;
}
