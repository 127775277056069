
.logoWrapper {
  width: 50vw;
  max-width: 200px;
  position: relative;
  z-index: 1000;
  margin: 0 auto;
  margin-bottom: 25px;
}

.logoWrapper img {
  display: block;
  width: 100%;
  margin-bottom: 20px;
}

#headerMessage {
  width: 60vw;
  max-width: 300px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}