
svg#tagline {
  width: 90vw;
  max-width: 500px;
  height: auto;
  margin: 0 auto;
  margin-bottom: 2vh;
}

#tagline path {
  fill: #ffffff;
}
