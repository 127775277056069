
@keyframes circleGrow {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

#semiCircle {
  transform: scale(0);
  transform-origin: bottom center;
  animation: circleGrow 1s 2s;
  animation-fill-mode: forwards;
}
