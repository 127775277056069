/* @keyframes redAlert {
  0% {
    background-position: 0 0%;
  }
  50% {
    background-position: 0 100%;
  }
  100% {
    background-position: 0 0%;
  }
} */

.App {
  /* display: grid; */
  /* justify-content: center; */
  /* align-items: center; */
  text-align: center;
  /* background: #191C52; */
  /* background: linear-gradient(#6EB5F3, #191C52); */
  height: 100vh;
  padding-bottom: 0vh;
  box-sizing: border-box;
  padding: 0;
}

/* .wrapper { */
  /* display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; */
/* } */

/* .App.redAlert {
  transition: all 1s;
  background-image: linear-gradient(tomato, darkred , black);
  background-size: 100% 200%;
  animation: redAlert 2s infinite ease-in-out;
} */

#layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow-x: hidden;
}

#topWrapper {
  /* background: #141C46; */
  /* width: 100vw; */
  position: relative;
  display: grid;
  justify-content: center;
  /* padding-top: 10vh; */
}

#bottomWrapper {
  display: grid;
  /* grid-template-columns: 1fr;
  justify-content:center; */
  background: linear-gradient(#6EB5F3, #191C52);
  padding: 3vh 15px;
  margin: 0;
}

.hideX {
  padding-top: 6vh;
  position: relative;
  display: block;
  width: 100vw;
  /* max-width: 100%; */
  overflow: hidden;
  /* outline: solid 3px hotpink */
}
